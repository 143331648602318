import logo from "src/img/logo.svg";

const Header = () => {
  return (
    <header className="flex flex-row items-center my-8 lg:my-6 mx-6">
      <a className="h-4 w-20 lg:h-6 lg:w-28" href="/">
        <img src={logo} alt="" />
      </a>
    </header>
  );
};

export default Header;
