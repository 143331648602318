import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export type ReCaptchaV2Props = {
  sitekey: string;
  onChange: (token: string | null, reset: () => void) => void;
  theme?: "dark" | "light";
  type?: "image" | "audio";
  size?: "compact" | "normal";
};
const ReCaptchaV2 = ({
  sitekey,
  onChange,
  theme = "light",
  type = "image",
  size = "normal",
}: ReCaptchaV2Props) => {
  const ref = useRef<any | null>(null);

  const reset = () => {
    ref?.current?.reset?.();
  };

  const onExpired = () => {
    console.error("expired recaptcha-v2");
  };
  const onErrored = () => {
    console.error("errored recaptcha-v2");
  };

  return (
    <div
      className="forms-info__captcha"
      style={{
        flexDirection: "column",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <ReCAPTCHA
        sitekey={sitekey}
        onChange={(token) => onChange(token, reset)}
        theme={theme}
        type={type}
        size={size}
        onExpired={onExpired}
        onErrored={onErrored}
        ref={ref}
      />
    </div>
  );
};

export default ReCaptchaV2;
