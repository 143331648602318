import { Button } from "../form/button";
import { CLIENT_ID } from "../../constants/environment-variables";
import github from "src/img/github.svg";
import { useState } from "react";

type Props = {
  // setAccessToken: (token: string) : void
};

function Login(props: Props) {
  const [isChecked, setIsChecked] = useState(false);
  const handleClick = () => {
    window.location.assign(
      "https://github.com/login/oauth/authorize?client_id=" + CLIENT_ID
    );
  };

  return (
    <div className="max-w-[500px] mx-auto flex flex-col">
      <p className="mb-4">
        To start developing on the Venom blockchain, follow these steps:
      </p>
      <ol className="numbered-list">
        <li className="first">
          Log in using your GitHub account to verify your identity.
        </li>
        <li>
          Request tokens by specifying the purpose and providing your wallet
          address.
        </li>
        <li>
          Tokens will be credited to your account after moderation and approval.
          When received, you can start building on the Venom blockchain.
        </li>
      </ol>

      <div className="relative flex items-start mt-10 mx-auto">
        <div className="flex items-center h-5">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="focus:ring-[#11a97d] h-4 w-4 text-[#11a97d] border-[#11a97d] rounded accent-[#11a97d] cursor-pointer"
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-white">
            I confirm that I am a developer.
          </label>
          <p id="comments-description" className="text-gray-400">
            (Please use{" "}
            <a
              href="https://venom.network"
              target="_blank"
              className="text-[#11a97d]" rel="noreferrer"
            >
              venom.network
            </a>{" "}
            for testing purposes)
          </p>
        </div>
      </div>

      <Button
        isDisabled={!isChecked}
        isLoading={false}
        onButtonClick={handleClick}
        additionalClassName={"btn-app loginBtn"}
        spanAdditionalClassName={!isChecked ? "loginSpan" :""}
      >
        <div className="flex flex-row items-center justify-center ">
          <img src={github} alt="GitHub" />
          Login with GitHub
        </div>
      </Button>
    </div>
  );
}

export default Login;
