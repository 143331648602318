import { useEffect, useRef, useState } from "react";
import { capitalizeFirstLetter, clipboard } from "src/helpers";

export enum AddressError {
  empty = "Empty string",
  valid = "Invalid address!",
}

export type AddressInputProps = {
  addressString: string;
  setAddressString: (addressString: string) => void;
  addressError: AddressError | undefined;
  networkLabel: string;
  isDisabled: boolean;
  isLoading: boolean;
  isButtonClicked: boolean;
};
const AddressInput = ({
  addressString,
  setAddressString,
  addressError,
  networkLabel,
  isDisabled,
  isLoading,
  isButtonClicked,
}: AddressInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isClipboardApiAvailable, setIsClipboardApiAvailable] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    const el = textareaRef?.current;
    if (el) {
      el.style.height = "auto";
      if (el.scrollHeight) {
        const minHeight = 100;
        el.style.height = Math.min(minHeight, el.scrollHeight) + "px";
        const _isOverflow = minHeight <= el.scrollHeight;
        setIsOverflow(_isOverflow);
        el.style.overflow = _isOverflow ? "auto" : "hidden";
      }
    }
  }, [addressString]);

  const validateText = (str: string) => {
    const maxLenght = 100;
    const regExp = new RegExp(/\b\s+\b/g);
    const result = str.trimStart().replaceAll(regExp, " ").slice(0, maxLenght);
    return result;
  };

  const onStringareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const str = e?.target.value || "";
    const result = validateText(str);
    setAddressString(result);
  };

  const checkClipboard = () => {
    const result = clipboard.check();
    setIsClipboardApiAvailable(result);
  };

  const onPaste = async () => {
    checkClipboard();
    const text = await clipboard.readText();
    const result = validateText(text || "");
    setAddressString(result);
  };

  const onClear = () => {
    setAddressString("");
  };

  useEffect(() => {
    checkClipboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`form-group ${
        addressError && isButtonClicked ? "has-error" : ""
      }`}
    >
      <label className="control-label" htmlFor="address_textarea">
        Input your {capitalizeFirstLetter(networkLabel)} VENOM wallet address*
      </label>
      <div className="form-group__wrap">
        <textarea
          className="form-control"
          rows={1}
          placeholder="address"
          value={addressString}
          onChange={onStringareaChange}
          ref={textareaRef}
          disabled={!addressError && isDisabled}
          spellCheck={false}
        ></textarea>
        {addressString.length ? (
          <span
            js-clear=""
            className="active"
            onClick={() => !isLoading && onClear()}
            style={{
              cursor: isLoading ? "default" : "pointer",
            }}
            hidden={isOverflow}
          />
        ) : (
          isClipboardApiAvailable && (
            <span
              js-paste=""
              onClick={() => !isLoading && onPaste()}
              style={{
                cursor: isLoading ? "default" : "pointer",
              }}
              hidden={isOverflow}
            >
              Paste
            </span>
          )
        )}
        {!!addressError && isButtonClicked && (
          <div className="invalid-feedback">{addressError}</div>
        )}
      </div>
    </div>
  );
};

export default AddressInput;
