import { social } from "src/constants";

const Social = () => {
  return (
    <div className="set-social">
      {social.map(({ key, icon, link }) => {
        return (
          <a key={key} href={link} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt="key" />
          </a>
        );
      })}
    </div>
  );
};

export default Social;
