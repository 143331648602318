import { convertToWhole } from "src/helpers";

export type AmountChoosingProps = {
  amountList?: string[];
  currentAmount?: string;
  setCurrentAmount: (currentAmount: string) => void;
  isDisabled: boolean;
};
const AmountChoosing = ({
  amountList,
  currentAmount,
  setCurrentAmount,
  isDisabled,
}: AmountChoosingProps) => {
  const onAmountItemClick = (current: string) => {
    setCurrentAmount(current);
  };

  return (
    <div className="form-group">
      <label className="control-label">Choose the amount of VENOM*</label>
      <div className="count-list">
        {amountList?.map((amount) => (
          <span
            className={currentAmount === amount ? "active" : ""}
            onClick={() => {
              if (!isDisabled) {
                onAmountItemClick(amount);
              }
            }}
          >
            {convertToWhole(amount)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default AmountChoosing;
