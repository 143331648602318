import discord from "src/img/social/discord.svg";
// import facebook from "src/img/social/fb.svg";
// import instagram from "src/img/social/inst.svg";
// import medium from "src/img/social/medium.svg";
// import site from "src/img/social/site.svg";
import telegram from "src/img/social/tg.svg";
import twitter from "src/img/social/tw.svg";
// import vk from "src/img/social/vk.svg";

export const defaultLink = "/";

type Social = {
  key: string;
  icon: string;
  link: string;
};
export const social: Social[] = [
  // {
  //   key: "site",
  //   icon: site,
  //   link: defaultLink,
  // },
  // {
  //   key: "facebook",
  //   icon: facebook,
  //   link: defaultLink,
  // },
  // {
  //   key: "instagram",
  //   icon: instagram,
  //   link: defaultLink,
  // },
  {
    key: "twitter",
    icon: twitter,
    link: "https://twitter.com/VenomFoundation",
  },
  {
    key: "discord",
    icon: discord,
    link: "https://discord.com/invite/KuMJaqh3WV",
  },
  {
    key: "telegram",
    icon: telegram,
    link: "https://t.me/VenomFoundationOfficial",
  },
  // {
  //   key: "medium",
  //   icon: medium,
  //   link: defaultLink,
  // },
  // {
  //   key: "vk",
  //   icon: vk,
  //   link: defaultLink,
  // },
];

export const links: Record<string, string> = {
  developerDiscordChannel: "https://discord.com/invite/KuMJaqh3WV",
  policy: "https://venom.foundation/privacy-policy",
  terms: "https://venom.foundation/terms-of-use",
};
