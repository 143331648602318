export const capitalizeFirstLetter = (str: string) => {
  const firstLeter = str[0] || "";
  const otherWordPart = str.slice(1);

  return `${firstLeter.toLocaleUpperCase()}${otherWordPart}`;
};

export const addressRegexp = new RegExp(/^([-+]?\d)+:[0-9a-fA-F]{64}$/);

const separator = ":";
const insertBetween = "...";
export const formatAddress = (
  address: string,
  leftDigit: number | undefined = 3,
  rightDigit: number | undefined = 4
) => {
  if (!addressRegexp.test(address)) return "address";

  const [rawLeftPart, rawRightPart] = address
    .toLocaleLowerCase()
    .split(separator);
  const rightPart = `${rawRightPart.slice(
    0,
    leftDigit
  )}${insertBetween}${rawRightPart.slice(-rightDigit)}`;

  return `${rawLeftPart}${separator}${rightPart}`;
};

export const formatAmount = (amount: number | string, fixed?: number) => {
  return amount.toLocaleString("en", {
    maximumFractionDigits: fixed ?? 0,
  });
};

const venomDecimal = 9;

export const convertToWhole = (
  n: number | string,
  fixed: number | undefined = 0,
  decimal: number | undefined = venomDecimal
) => (Number(n) / 10 ** decimal).toFixed(fixed);

export const convertToFractional = (
  n: number,
  decimal: number | undefined = venomDecimal
) => Math.round(n / 10 ** decimal) || 0;

const check = () => {
  // @ts-ignore
  if (navigator?.clipboard?.readText) {
    return true;
  } else {
    console.error("can't copy text from the clipboard");
    return false;
  }
};

const readText = () => {
  if (check()) return navigator.clipboard.readText();
  else return null;
};

export const clipboard = {
  readText,
  check,
};
