import { useEffect, useState } from "react";
import error from "src/img/error.svg";
import failed from "src/img/failed.svg";
import success from "src/img/success.svg";

type SharedCommonProps = {
  onClose?: () => void;
  text: string;
};
type CommonAlertProps = SharedCommonProps & {
  alertClass: string;
  title: string;
  image: {
    src: string;
    alt?: string;
  };
};
const delayInMs = 1000;
const CommonAlert = ({
  alertClass,
  onClose,
  title,
  text,
  image: { src, alt },
}: CommonAlertProps) => {
  const [isShowOpacity, setIsShowOpacity] = useState(true);
  const [isShow, setIsShow] = useState(true);

  const onCloseClick = () => {
    setIsShowOpacity(false);
    setTimeout(() => {
      setIsShow(false);
      onClose?.();
    }, delayInMs);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      onCloseClick();
    }, 10000);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShow) return <></>;

  return (
    <div
      className={`alert ${alertClass}`}
      style={{
        transition: `all ${delayInMs}ms ease-in`,
        opacity: isShowOpacity ? "1" : "0",
      }}
    >
      <div className="alert__close" onClick={onCloseClick} />
      <div className="alert__header">
        {title}
        <img src={src} alt={alt || ""} />
      </div>
      <div className="alert__text">{text}</div>
    </div>
  );
};

type ErrorAlertProps = SharedCommonProps;
const ErrorAlert = ({ text, onClose }: ErrorAlertProps) => {
  return (
    <CommonAlert
      alertClass="alert_error"
      onClose={onClose}
      title={"Failed"}
      image={{
        src: failed,
        alt: "failed",
      }}
      text={text}
    />
  );
};

type SuccessAlertProps = SharedCommonProps;
const SuccessAlert = ({ text, onClose }: SuccessAlertProps) => {
  return (
    <CommonAlert
      alertClass="alert_success"
      onClose={onClose}
      title={"Success"}
      image={{
        src: success,
        alt: "success",
      }}
      text={text}
    />
  );
};

type InfoAlertProps = SharedCommonProps;
const InfoAlert = ({ text, onClose }: InfoAlertProps) => {
  return (
    <CommonAlert
      alertClass="alert_error"
      onClose={onClose}
      title={"Try again later"}
      image={{
        src: error,
        alt: "error",
      }}
      text={text}
    />
  );
};

type AlertWrapProps = {
  children: React.ReactNode;
};
const AlertWrap = ({ children }: AlertWrapProps) => {
  return <div className="alert__wrap">{children}</div>;
};

export default AlertWrap;

export { ErrorAlert, InfoAlert, SuccessAlert };
