import { LoaderWrapper } from "src/components/loader";


export type ButtonProps = {
  onButtonClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
  children: string | JSX.Element;
  height?: number | string;
  additionalClassName?: string;
  spanAdditionalClassName?: string
};
const Button = ({
  onButtonClick,
  isDisabled,
  isLoading,
  children,
  height = "20px",
  additionalClassName = "", spanAdditionalClassName,
}: ButtonProps) => {
  return (
    <button
      className={`btn btn-primary ${additionalClassName}`}
      type="button"
      disabled={isDisabled || isLoading}
      onClick={() => {
        if (!isDisabled || isLoading) {
          onButtonClick();
        }
      }}
    >
      <LoaderWrapper isHidden={!isLoading} height={height}>
        <span className={`${isLoading  ? "opacity-30" : spanAdditionalClassName}`} aria-disabled={isDisabled || isLoading}>{children}</span>
      </LoaderWrapper>
    </button>
  );
};

export default Button;
