const TitleGroup = () => {
  return (
    <>
      <h1 className="text-white leading-8 mb-4">Venom Faucet for Developers</h1>

      <p className="forms-page__desc text-sm lg:text-base">
        Feel free to request Test Venom tokens to your wallet
      </p>
    </>
  );
};

export default TitleGroup;
