import { useEffect, useState } from "react";
import { Nav } from "src/components/form/nav";
// import { Subscribe } from "src/components/subscribe";
import { NetworkTab } from "./form/network-tab";
import { AddressError } from "./form/address-input";
import { ReasonError } from "./form/reason-requesting";
import { Network } from "../types";
import { addressRegexp, capitalizeFirstLetter } from "../helpers";
import { servedInfo } from "../constants";
import useReCaptcha from "../hooks/useReCaptcha";
import useApi from "../hooks/useApi";
import Modal from "./Modal";

export const Form = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const [network, setNetwork] = useState<Network>("testnet");

  const [addressString, setAddressString] = useState("");
  const [addressError, setAddressError] = useState<AddressError | undefined>();
  const [isAddressCorrect, setIsAddressCorrect] = useState(false);

  const [currentAmount, setCurrentAmount] = useState<string | undefined>();

  const [reasonText, setReasonText] = useState("");
  const [reasonError, setReasonError] = useState<ReasonError | undefined>();

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { reCaptchaV2, recaptchaToken, recaptchaReset } = useReCaptcha();

  const {
    isApiAvailable,
    alerts,
    //
    isPossibleValuesLoading,
    possibleValues,
    //
    createRequest,
    isCreationRequestLoading,
    //
    subscribeRequest,
    isSubscribeRequestLoading,
  } = useApi();

  useEffect(() => {
    if (!isPossibleValuesLoading)
      setCurrentAmount(possibleValues?.[network]?.[0]);
  }, [isPossibleValuesLoading, network]);

  useEffect(() => {
    const address =
      !!addressString && addressError === undefined && isAddressCorrect;
    const reason = !!reasonText.trim() && reasonError === undefined;
    const api =
      !!isApiAvailable && !isCreationRequestLoading && !isPossibleValuesLoading;

    const ok = api && address && (isReasonRequestingShow ? reason : true);

    setIsButtonDisabled(isButtonClicked && !ok);
  }, [
    addressString,
    addressError,
    isAddressCorrect,
    reasonText,
    reasonError,
    isApiAvailable,
    isCreationRequestLoading,
    network,
    isButtonClicked,
    isPossibleValuesLoading,
  ]);

  const createRequestReset = () => {
    setAddressString("");
    setAddressError(undefined);
    setIsAddressCorrect(false);

    setCurrentAmount(possibleValues?.[network]?.[0]);

    setReasonText("");
    setReasonError(undefined);

    setIsButtonClicked(false);
    setIsButtonDisabled(true);

    recaptchaReset?.();
  };

  const checkAddressInput = (
    str: string | undefined,
    isFromClick: boolean | undefined = false
  ) => {
    const isCorrect = addressRegexp.test(str || "");

    setIsAddressCorrect(isCorrect);

    if (isButtonClicked || isFromClick) {
      if (!str?.length) {
        setAddressError(AddressError.empty);
      } else if (!isCorrect) {
        setAddressError(AddressError.valid);
      } else {
        setAddressError(undefined);
      }
    }

    return isCorrect && !!str?.length;
  };

  const onAddressInputChange = (str: string | undefined = "") => {
    setAddressString(str);
    checkAddressInput(str);
  };

  const isReasonRequestingShow =
    // todo: mainnet
    // network === "mainnet" || currentAmount === String(100 * 10 ** 9);
    network === "testnet";

  const checkReasonInput = (
    str: string | undefined,
    isFromClick: boolean | undefined = false
  ) => {
    if (isButtonClicked || isFromClick) {
      if (!str?.length) {
        setReasonError(ReasonError.empty);
        return false;
      } else if (str?.length < 150) {
        setReasonError(ReasonError.minLength);
        return false;
      } else {
        setReasonError(undefined);
      }
    }

    return !!str?.length;
  };

  const onReasonInputChange = (str: string | undefined = "") => {
    setReasonText(str);
    checkReasonInput(str);
  };

  const createRequestCall = async (token: string | undefined) => {
    const isGoodAddress = checkAddressInput(addressString, true);
    const isGoodReason = checkReasonInput(reasonText, true);
    const isGood =
      isGoodAddress && (isReasonRequestingShow ? isGoodReason : true);

    if (addressString && currentAmount && isGood && token) {
      const success = await createRequest(
        {
          network,
          requestBody: {
            recipient: addressString,
            value: currentAmount,
            creationReason: isReasonRequestingShow ? reasonText : undefined,
          },
          token,
        },
        createRequestReset
      );
      setIsSuccessRequest(success)
      setIsOpen(true)
    }
  };

  const onButtonClick = () => {
    setIsButtonClicked(true);

    createRequestCall(recaptchaToken);
  };

  // const onSubscribeButtonClick = (email: string, reset: () => void) => {
  //   subscribeRequest(
  //     {
  //       requestBody: {
  //         email,
  //       },
  //       token: recaptchaToken!,
  //     },
  //     reset
  //   );
  // };

  return (
    <div className="forms-info w-full">
      <Nav
        network={network}
        setNetwork={setNetwork}
        isLoading={isCreationRequestLoading || isPossibleValuesLoading}
      />
      <div className="forms-info__content tab-content">
        <NetworkTab
          network={network}
          networkLabel={capitalizeFirstLetter(network)}
          //
          addressString={addressString}
          setAddressString={onAddressInputChange}
          addressError={addressError}
          //
          amountList={possibleValues[network]}
          currentAmount={currentAmount}
          setCurrentAmount={setCurrentAmount}
          //
          isReasonRequestingShow={isReasonRequestingShow}
          reasonText={reasonText}
          setReasonText={onReasonInputChange}
          reasonError={reasonError}
          //
          isDisabled={isButtonDisabled || isPossibleValuesLoading}
          isLoading={isCreationRequestLoading}
          isButtonClicked={isButtonClicked}
          onButtonClick={onButtonClick}
          //
          reCaptchaV2={reCaptchaV2}
          recaptchaToken={recaptchaToken}
          //
          servedAddress={servedInfo?.[network]?.servedAddress}
          servedAmount={servedInfo?.[network]?.servedAmount}
        />
      </div>
      {/* <Subscribe */}
      {/*   isDisabled={isSubscribeRequestLoading} */}
      {/*   isLoading={isSubscribeRequestLoading} */}
      {/*   onSubscribe={onSubscribeButtonClick} */}
      {/*   recaptchaToken={recaptchaToken} */}
      {/* /> */}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}  isSuccess={isSuccessRequest}/>
    </div>
  );
};
