import React from "react";
import Head from "react-helmet";

const location = window.location;

const seo = {
  type: "website",
  // todo: mainnet
  // title: "Venom testnet/mainnet faucet",
  title: "Venom testnet faucet",
  description: "Feel free to request Test Venom tokens to your wallet",
  url: location.origin,
  image: {
    link: `${location.origin}/img/venombg.jpg`,
    width: "1000",
    height: "1000",
  },

  tileColor: "#ffffff",
  themeColor: "#0F1116",
};

const SEO = () => {
  return (
    <Head>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />

      <meta property="og:type" content={seo.type} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image.link} />
      {/* <meta property="og:image:alt" content={seo.title} /> */}
      <meta property="og:image:width" content={seo.image.width} />
      <meta property="og:image:height" content={seo.image.height} />

      <link rel="image_src" href={seo.image.link} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="favicon/favicon-32x32.png"
      />
      <link rel="icon" type="image/x-icon" href="favicon/favicon.ico" />
      <meta name="msapplication-TileColor" content={seo.tileColor} />
      <meta name="theme-color" content={seo.themeColor} />
    </Head>
  );
};

export default SEO;
