import { Network } from "src/types";
import {defaultNetworkName} from "../../../constants";

type Tab = {
  key: Network;
  label: string;
};
const tabs: Tab[] = [
  {
    key: 'testnet',
    label: defaultNetworkName,
  },
  // todo: mainnet
  // {
  //   key: "mainnet",
  //   label: "Mainnet",
  // },
];

type NavProps = {
  network: Network;
  setNetwork: (network: Network) => void;
  isLoading: boolean;
};
const Nav = ({ network, setNetwork, isLoading }: NavProps) => {
  const onTabClick = (_network: Network) => {
    if (!isLoading) setNetwork(_network);
  };

  const cursorStyle = {
    cursor: isLoading ? "default" : "pointer",
  };
  const oneTabStyle =
    tabs.length < 2
      ? {
          width: "100%",
        }
      : {};

  return (
    <div className="forms-info__nav nav" role="tablist">
      {tabs.map(({ key, label }) => {
        return (
          <div
            key={key}
            className={`forms-info__tab nav-item ${
              network === key ? "active" : ""
            }`}
            onClick={() => onTabClick(key)}
            style={{
              ...cursorStyle,
              ...oneTabStyle,
            }}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default Nav;
