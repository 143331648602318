import {
  capitalizeFirstLetter,
  convertToWhole,
  formatAddress,
  formatAmount,
} from "src/helpers";
import { ServedInfo } from "src/types";

export type ServiceProviderInfoProps = {
  networkLabel: string;
} & ServedInfo;
const ServiceProviderInfo = ({
  networkLabel,
  servedAddress,
  servedAmount,
}: ServiceProviderInfoProps) => {
  return (
    <div className="forms-info__text">
      {capitalizeFirstLetter(networkLabel)} VENOM tokens are sent from: <br />
      <a
        {...(servedAddress.link ? { href: servedAddress.link } : {})}
        target="_blank"
        rel="noopener noreferrer"
      >
        {formatAddress(servedAddress.address)}
      </a>
      {!!servedAmount && (
        <>
          {" "}
          with{" "}
          <a
            {...(servedAmount.link ? { href: servedAmount.link } : {})}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatAmount(convertToWhole(servedAmount.amount))}
          </a>{" "}
          VENOMs.
        </>
      )}
    </div>
  );
};

export default ServiceProviderInfo;
