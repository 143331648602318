import { API_URL } from "src/constants";
import {
  CreationRequest,
  CreationResponse,
  InfoResponce,
  Network,
  SubscriptionRequest,
} from "src/types";
import { get, post } from "./api-methods";

const apiPath = API_URL;
const pingApi = async () => {
  return !!(await get(apiPath));
};

// value must be string integer;
// value must be grater then or equal to 1000000;
// value must be less then or equal to 100000000000;
const getPossibleRequestValuesPath = `${apiPath}/faucet-requests/settings/possible-request-values`;
export type GetPossibleValues = {
  network: Network;
};
const getPossibleValuesRequest = async ({ network }: GetPossibleValues) => {
  return await get<string[]>( // number string array
    `${getPossibleRequestValuesPath}/${network}`
  );
};

const createFaucetRequestPath = `${apiPath}/faucet-requests/faucet-request`;
export type CreateFaucetRequest = {
  network: Network;
  requestBody: CreationRequest;
  token: string;
};
const createFaucetRequest = async ({
  network,
  requestBody,
  token,
}: CreateFaucetRequest) => {
  return await post<CreationResponse | InfoResponce>(
    `${createFaucetRequestPath}/${network}`,
    requestBody,
    {
      credentials: "include",

      headers: {
        recaptcha: token,
      },
    }
  );
};

const subscribePath = `${apiPath}/user-data`;
export type SubscribeFaucetRequest = {
  requestBody: SubscriptionRequest;
  token: string;
};
const subscribeFaucetRequest = async ({
  requestBody,
  token,
}: SubscribeFaucetRequest) => {
  return await post<{}>(subscribePath, requestBody, {
    credentials: "include",
    headers: {
      withCredentials: "true",
      recaptcha: token,
    },
  });
};

const authPath = `${apiPath}/auth/login`;
const authLogin = async (code: string) => {
  const response = await fetch(authPath + "?code=" + code, {
    method: "POST",
    credentials: "include",
    headers: {
      withCredentials: "true",
    },
  });

  // console.log("response", response);
  return response.ok;
};

export {
  authLogin,
  pingApi,
  getPossibleValuesRequest,
  createFaucetRequest,
  subscribeFaucetRequest,
};
