import { CSSProperties } from "react";
import style from "./index.module.css";

type LoaderProps = {
  height: string | number;
};
const Loader = ({ height }: LoaderProps) => {
  return (
    <div
      className={style["lds-ellipsis"]}
      style={
        {
          "--height": typeof height === "number" ? `${height}px` : height,
        } as CSSProperties
      }
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

type LoaderWrapperProps = {
  isHidden: boolean;
  children: React.ReactNode;
  height: LoaderProps["height"];
};
export const LoaderWrapper = ({
  isHidden,
  children,
  height,
}: LoaderWrapperProps) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        hidden={isHidden}
      >
        <Loader height={height} />
      </div>
      {children}
    </div>
  );
};

export default Loader;
