import { config } from "./config";

const check = ({ name }: { name: string }) => {
  if (!process.env[name]) {
    const str = `[mode using dotenv] The environment variable${
      name ? ` ${name}` : ""
    } is not defined!`;
    // eslint-disable-next-line no-alert
    alert(str);
    throw new Error(str);
  }
  return false;
};

const USE_ENV = (process.env.REACT_APP_USE_ENV as string) === "true";

if (USE_ENV) {
  check({
    name: "REACT_APP_API_URL",
  });
  check({
    name: "REACT_APP_RE_CAPTCHA_KEY",
  });
  check({
    name: "REACT_APP_CLIENT_ID",
  });
}

export const API_URL = USE_ENV
  ? (process.env.REACT_APP_API_URL as string)
  : config.apiUrl;
export const RE_CAPTCHA_KEY = USE_ENV
  ? (process.env.REACT_APP_RE_CAPTCHA_KEY as string)
  : config.reCaptchaKey;
export const CLIENT_ID = USE_ENV
  ? (process.env.REACT_APP_CLIENT_ID as string)
  : config.clientId;
 export const MAINTENANCE_MODE = config.maintenanceMode
