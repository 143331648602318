import { links } from "src/constants";
import { Info } from "src/components/info";
import { Social } from "src/components/social";

const Footer = () => {
  return (
    <footer className="mt-auto">
      <Social />
      <Info />
      <div className="footer-custom">
        <a href={links.policy} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a href={links.terms} target="_blank" rel="noopener noreferrer">
          Terms of Use
        </a>
      </div>
    </footer>
  );
};

export default Footer;
