import { useState } from "react";
import { ReCaptchaV2 as ReCaptchaV2Raw } from "src/components/recaptcha-v2";
import { recaptchaV2Options } from "src/constants";

const useReCaptcha = () => {
  const [recaptchaToken, setRrecaptchaToken] = useState<string | undefined>();
  const [recaptchaReset, setRrecaptchaReset] =
    useState<() => void | undefined>();

  const onCaptchaChange = (token: string | null, reset: () => void) => {
    setRrecaptchaToken(undefined);
    if (token) {
      setRrecaptchaToken(token);
    } else {
      console.error(`Bad token:: ${token}`);
    }
    setRrecaptchaReset(() => () => {
      reset();
      setRrecaptchaToken(undefined);
    });
  };

  const reCaptchaV2 = (
    <ReCaptchaV2Raw
      onChange={(token, reset) => onCaptchaChange(token, reset)}
      {...recaptchaV2Options}
    />
  );

  return {
    reCaptchaV2: reCaptchaV2,
    recaptchaToken,
    recaptchaReset,
  };
};

export default useReCaptcha;
