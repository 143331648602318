import { useEffect, useState } from "react";
import {
  CreateFaucetRequest,
  createFaucetRequest,
  getPossibleValuesRequest,
  pingApi,
  subscribeFaucetRequest,
  SubscribeFaucetRequest,
} from "src/api";
import { Alert, CreationResponse, InfoResponce } from "src/types";

const useApi = () => {
  const [alerts, setAlerts] = useState<Alert[]>([
    // {
    //   key: "error",
    //   text: "Error text example",
    // },
    // {
    //   key: "success",
    //   text: "Success text example",
    // },
    // {
    //   key: "info",
    //   text: "Info text example",
    // },
  ]);
  const [isApiAvailable, setIsApiAvailable] = useState<boolean | undefined>(
    undefined
  );

  const checkApi = async () => {
    try {
      const answer = await pingApi();
      setIsApiAvailable(answer);
    } catch (error) {
      setIsApiAvailable(false);

      setAlerts((old) => {
        return [
          ...old,
          {
            key: "error",
            text: "The server API is unavailable",
          },
        ];
      });
    }
  };

  useEffect(() => {
    checkApi();
  }, []);

  // getPossibleValuesRequest
  const [isPossibleValuesLoading, setIsPossibleValuesLoading] = useState(false);
  const [testnetPossibleValues, setTestnetPossibleValues] = useState<
    string[] | undefined
  >();
  // todo: mainnet
  // const [mainnetPossibleValues, setMainnetPossibleValues] = useState<
  //   string[] | undefined
  // >();

  const getPossibleValues = async () => {
    setIsPossibleValuesLoading(true);
    setTestnetPossibleValues(undefined);
    // todo: mainnet
    // setMainnetPossibleValues(undefined);

    try {
      const testnetPromice = getPossibleValuesRequest({
        network: "testnet",
      });
      // todo: mainnet
      // const mainnetPromice = getPossibleValuesRequest({
      //   network: "mainnet",
      // });

      const [
        testnet,
        // todo: mainnet
        // mainnet
      ] = await Promise.all([
        testnetPromice,
        // todo: mainnet
        // mainnetPromice,
      ]);

      if (
        Array.isArray(testnet) &&
        typeof testnet[0] === "string"
        // todo: mainnet
        // &&
        // Array.isArray(mainnet) &&
        // typeof mainnet[0] === "string"
      ) {
        setTestnetPossibleValues(testnet);
        // todo: mainnet
        // setMainnetPossibleValues(mainnet);
      } else {
        throw new Error("");
      }
    } catch (error) {
      setTestnetPossibleValues(undefined);
      // todo: mainnet
      // setMainnetPossibleValues(undefined);

      setAlerts((old) => {
        return [
          ...old,
          {
            key: "error",
            text: "Possible values request failure",
          },
        ];
      });
    }

    setIsPossibleValuesLoading(false);
  };

  useEffect(() => {
    if (
      isApiAvailable &&
      !testnetPossibleValues
      // todo: mainnet
      // || !mainnetPossibleValues
    ) {
      getPossibleValues();
    }
  }, [isApiAvailable]);

  // createFaucetRequest
  const [isCreationRequestLoading, setIsCreationRequestLoading] =
    useState(false);
  const [creationResponse, setCreationResponse] = useState<
    CreationResponse | null | undefined
  >();

  const createRequest = async (
    params: CreateFaucetRequest,
    reset: () => void
  ): Promise<boolean> => {
    setIsCreationRequestLoading(true);
    setCreationResponse(undefined);

    try {
      const _creationResponse = await createFaucetRequest(params);

      const __creationResponse =
        (_creationResponse !== true && _creationResponse) || null;

      if (
        // @ts-ignore
        typeof __creationResponse?.status === "string"
      ) {
        const goodCreationResponse = __creationResponse as CreationResponse;
        if (
          ["created", "approving", "approved"].includes(
            goodCreationResponse?.status || ""
          )
        ) {
          setAlerts((old) => {
            return [
              ...old,
              {
                key: "success",
                text:
                  goodCreationResponse?.status === "created"
                    ? "VENOMs will be sent to your address after approval by the admin"
                    : "VENOMs have been sent to the specified address",
              },
            ];
          });
          setCreationResponse(goodCreationResponse);
        } else {
          throw new Error("Failure status");
        }
      } else if (!__creationResponse) {
        throw new Error("Unknown error");
      } else {
        setCreationResponse(null);

        const goodCreationResponse = __creationResponse as InfoResponce;
        if (goodCreationResponse?.statusCode < 200 || goodCreationResponse?.statusCode > 299) {
          reset();
          setIsCreationRequestLoading(false);
          return false
        }

        setAlerts((old) => {
          return [
            ...old,
            {
              key: "info",
              text: goodCreationResponse?.info || "",
            },
          ];
        });
      }
    } catch (error) {
      setCreationResponse(null);

      setAlerts((old) => {
        return [
          ...old,
          {
            key: "error",
            text: "The application is not successful, but it can be completed successfully later. Track status in your wallet", // "The request wasn't sent successfully",
          },
        ];
      });
      return false;
    }

    reset();
    setIsCreationRequestLoading(false);
    return true;
  };

  // subscribeFaucetRequest
  const [isSubscribeRequestLoading, setIsSubscribeRequestLoading] =
    useState(false);
  const [subscribeResponse, setSubscribeResponse] = useState<
    boolean | undefined
  >();

  const subscribeRequest = async (
    params: SubscribeFaucetRequest,
    reset: () => void
  ) => {
    if (!params.token) {
      setAlerts((old) => {
        return [
          ...old,
          {
            key: "error",
            text: "You have to check reCaptcha first",
          },
        ];
      });
      return;
    }
    setIsSubscribeRequestLoading(true);
    setSubscribeResponse(undefined);

    try {
      const _subscribeResponse = await subscribeFaucetRequest(params);
      if (!!_subscribeResponse) {
        setAlerts((old) => {
          return [
            ...old,
            {
              key: "success",
              text: "Subscription successfully created",
            },
          ];
        });
        setSubscribeResponse(true);
      } else {
        throw new Error("");
      }
    } catch (error) {
      setSubscribeResponse(undefined);

      setAlerts((old) => {
        return [
          ...old,
          {
            key: "error",
            text: "Subscription failure",
          },
        ];
      });
    }

    reset();
    setIsSubscribeRequestLoading(false);
  };

  const resetAll = async () => {
    checkApi();

    setIsCreationRequestLoading(false);
    setCreationResponse(undefined);

    setIsSubscribeRequestLoading(false);
    setSubscribeResponse(undefined);
  };

  return {
    isApiAvailable,
    alerts,
    //
    getPossibleValues,
    isPossibleValuesLoading,
    possibleValues: {
      testnet: testnetPossibleValues,
      // todo: mainnet
      // mainnet: mainnetPossibleValues,
    },
    //
    createRequest,
    isCreationRequestLoading,
    creationResponse,
    //
    subscribeRequest,
    isSubscribeRequestLoading,
    subscribeResponse,
    //
    reset: resetAll,
  };
};

export default useApi;
