import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isSuccess: boolean;
};

export default function Modal({ isOpen, setIsOpen, isSuccess }: ModalProps) {
  const btnColor = isSuccess ? 'bg-[#11a97d] hover:bg-[#0f926c]' : "bg-[#17a2b8] hover:bg-[#148da0]"
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-md transform overflow-hidden rounded-2xl bg-black border p-6 
                align-middle shadow-xl transition-all text-center
                 ${isSuccess ? ' border-[#11a97d]' : ' border-[#17a2b8]'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-medium leading-6  ${isSuccess ? ' text-[#11a97d]' : ' text-[#17a2b8]'}`}
                  >
                    {isSuccess ? "Submit is succesfull" : "Sending failed"}
                  </Dialog.Title>
                  {isSuccess ? <div className="mt-4">
                    <p className="text-sm text-gray-200">
                      Thank you for submitting your request for tokens on the
                      faucet for developers. Our foundation team has received
                      your request and will review it as soon as possible. If
                      your request is approved, we will send the tokens to the
                      wallet address you provided.
                    </p>
                    <p className="text-sm mt-2 text-gray-200">
                      In the unlikely event that you do not receive the tokens
                      within 12 hours, please resubmit your request with more
                      detailed information in the justification field. This will
                      help us better understand your request and ensure a
                      smoother review process.
                    </p>
                  </div> : <div className="mt-4">
                    <p className="text-sm text-gray-200">
                        Verify that all requirements are fulfilled for token acquisition:
                    </p>
                    <p className="text-sm mt-2 text-gray-200">
                        1. The GitHub account must be at least 7 days old. <br/>
                        2. Only one token request can be submitted per day
                    </p>
                  </div> }


                  <div className="mt-6">
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white  focus:outline-none focus-visible:ring-0 ${btnColor}` }
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
