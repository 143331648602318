import {
  AddressInput,
  AddressInputProps,
} from "src/components/form/address-input";
import {
  AmountChoosing,
  AmountChoosingProps,
} from "src/components/form/amount-choosing";
import { Button, ButtonProps } from "src/components/form/button";
import {
  ReasonRequesting,
  ReasonRequestingProps,
} from "src/components/form/reason-requesting";
import {
  ServiceProviderInfo,
  ServiceProviderInfoProps,
} from "src/components/form/service-provider-info";
import { capitalizeFirstLetter } from "src/helpers";
import { Network } from "src/types";

type NetworkTabProps = {
  network: Network;
  isReasonRequestingShow: boolean;
  reCaptchaV2: false | JSX.Element;
  recaptchaToken: string | undefined;
} & AddressInputProps &
  AmountChoosingProps &
  ReasonRequestingProps &
  Omit<ButtonProps, "children"> &
  ServiceProviderInfoProps;
const NetworkTab = ({
  network,
  networkLabel,
  //
  amountList,
  currentAmount,
  setCurrentAmount,
  //
  isReasonRequestingShow,
  reasonText,
  setReasonText,
  reasonError,
  //
  addressString,
  setAddressString,
  addressError,
  //
  onButtonClick,
  //
  servedAddress,
  servedAmount,
  //
  isDisabled,
  isLoading,
  isButtonClicked,
  //
  reCaptchaV2,
  recaptchaToken,
}: NetworkTabProps) => {
  return (
    <div className="forms-info__plane tab-pane fade show active">
      {/* // todo: mainnet */}
      {/* {network === "mainnet" && <VenomRequest />} */}

      <AddressInput
        addressString={addressString}
        setAddressString={setAddressString}
        addressError={addressError}
        networkLabel={networkLabel}
        isDisabled={isDisabled}
        isButtonClicked={isButtonClicked}
        isLoading={isLoading}
      />

      <AmountChoosing
        amountList={amountList}
        currentAmount={currentAmount}
        setCurrentAmount={setCurrentAmount}
        isDisabled={isDisabled && isLoading}
      />

      {isReasonRequestingShow && (
        <ReasonRequesting
          reasonText={reasonText}
          setReasonText={setReasonText}
          reasonError={reasonError}
          isDisabled={isDisabled}
          isButtonClicked={isButtonClicked}
        />
      )}

      <div
        style={{
          width: "100%",
          minHeight: "78px",
          opacity: isLoading ? 0 : 1,
        }}
      >
        {reCaptchaV2}
      </div>

      <Button
        onButtonClick={onButtonClick}
        isDisabled={isDisabled || !recaptchaToken}
        isLoading={isLoading}
        additionalClassName="w-full"
      >
        {`Request ${capitalizeFirstLetter(networkLabel)} VENOM tokens`}
      </Button>

      <ServiceProviderInfo
        networkLabel={networkLabel}
        servedAddress={servedAddress}
        servedAmount={servedAmount}
      />
    </div>
  );
};

export default NetworkTab;
