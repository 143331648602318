import React from 'react';
import maintenance from '../../img/maintenance-new.svg'

const Maintenance = () => {
    return (
        <section className="section-wrap">
            {/*<Helmet>*/}
            {/*    <title>*/}
            {/*        {intl.formatMessage({*/}
            {/*                id: 'main-page.everscale-title',*/}
            {/*            })*/}
            {/*            + (NETWORK.type === NETWORK_TYPE.TESTNET*/}
            {/* F               ? ' (testnet)'*/}
            {/*                : NETWORK.type === NETWORK_TYPE.DEVNET*/}
            {/*                    ? ' (devnet)'*/}
            {/*                    : '')}*/}
            {/*    </title>*/}
            {/*</Helmet>*/}
            <div className="layout-container maintenance">
                <img
                    className="maintenance--main-img"
                    src={maintenance}
                    alt="Under maintenance"
                />
                <h1 className="maintenance--title">Testnet Faucet is closed now</h1>
                {/*<p className="text">*/}
                {/*    Venom Blockchain Is Under Maintenance: To stay informed*/}
                {/*    about the latest developments and announcements,*/}
                {/*    <p style={{ marginTop: '20px' }}>we encourage you to check Venom’s official social media channels.</p>*/}
                {/*</p>*/}
                <div className="line" />
            </div>
        </section>
    );
};

export default Maintenance;
