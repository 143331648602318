import { useEffect, useRef } from "react";

export enum ReasonError {
  empty = "Empty string",
  minLength = "Message should be at least 150 characters",
  // maxLenght = '',
}

export type ReasonRequestingProps = {
  reasonText: string;
  setReasonText: (reasonText: string) => void;
  reasonError: ReasonError | undefined;
  isDisabled: boolean;
  isButtonClicked: boolean;
};
const ReasonRequesting = ({
  reasonText,
  setReasonText,
  reasonError,
  isDisabled,
  isButtonClicked,
}: ReasonRequestingProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const el = textareaRef?.current;
    if (el) {
      el.style.height = "auto";
      if (el.scrollHeight) {
        const minHeight = 100;
        el.style.height = Math.min(minHeight, el.scrollHeight) + "px";
        const _isOverflow = minHeight <= el.scrollHeight;
        el.style.overflow = _isOverflow ? "auto" : "hidden";
      }
    }
  }, [reasonText]);

  const validateText = (str: string) => {
    const maxLenght = 2000;
    // const regExp = new RegExp(/\b\s+\b/g);
    const result = str
      .trimStart()
      // .replaceAll(regExp, " ")
      .slice(0, maxLenght);
    return result;
  };

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const str = e?.target.value || "";
    const result = validateText(str);
    setReasonText(result);
  };

  return (
    <div
      className={`form-group ${
        reasonError && isButtonClicked ? "has-error" : ""
      }`}
    >
      <label className="control-label" htmlFor="reason_textarea">
        Reason for requesting VENOM*
      </label>
      <div className="form-group__wrap">
        <textarea
          className="form-control !pr-0"
          rows={3}
          placeholder="Describe the idea of your project in as much detail as possible. This faucet is only for developers with an active GitHub account and a project idea."
          value={reasonText}
          onChange={onTextareaChange}
          ref={textareaRef}
          style={{
            overflow: "hidden",
            transition: "all 1s ease-out",
          }}
          // disabled={!reasonError && isDisabled}
          spellCheck={false}
        ></textarea>
        {!!reasonError && isButtonClicked && (
          <div className="invalid-feedback">{reasonError}</div>
        )}
      </div>
    </div>
  );
};

export default ReasonRequesting;
