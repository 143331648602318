import { useEffect, useState } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import Header from "src/components/header/Header";
import { TitleGroup } from "src/components/title-group";
import netDecor from "src/img/net-decor.svg";
import { authLogin } from "./api";
import "./app.css";
import { AlertWrap } from "./components/alert-wrap";
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
} from "./components/alert-wrap/AlertWrap";
import { Footer } from "./components/footer";
import { Form } from "./components/Form";
import { Login } from "./components/login";
import SEO from "./head";
import useApi from "./hooks/useApi";
import {MAINTENANCE_MODE} from "./constants";
import Maintenance from "./components/maintenance/Maintenance";

const App = () => {
  const navigate = useNavigate();

  const { alerts } = useApi();

  const [isGitHubLogged, setIsGitHubLogged] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;

    const queryParams = new URLSearchParams(queryString);
    const code = queryParams.get("code");

    const fetchAuth = async (cde: string) => {
      const result = await authLogin(cde);
      setIsGitHubLogged(result);
      navigate("/");
    };
    if (code) {
      // console.log("code", code);
      fetchAuth(code);
    }
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <img
        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-10"
        src={netDecor}
        alt="decor"
      />
      <Header />
      <div className="px-2 h-full flex flex-col">
        <AlertWrap>
          {alerts.map((alert) => {
            switch (alert.key) {
              case "error":
                return (
                  <ErrorAlert onClose={alert?.onClose} text={alert.text} />
                );
              case "success":
                return (
                  <SuccessAlert onClose={alert?.onClose} text={alert.text} />
                );
              case "info":
              default:
                return <InfoAlert onClose={alert?.onClose} text={alert.text} />;
            }
          })}
        </AlertWrap>
        <section className="forms-page flex flex-col h-full">
          <div className="my-auto">
            <TitleGroup />
            {isGitHubLogged ? <Form /> : <Login />}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const AppWrapper = () => {
    if (MAINTENANCE_MODE) {
        return (
            <Maintenance />
        )
    }
  return (
    <Router>
      <SEO />
      <App />
    </Router>
  );
};

export default AppWrapper;
