import { links } from "src/constants";

const Info = () => {
  return (
    <div className="forms-page__info">
      If you haven't received any tokens or have another request, please contact
      us on the{" "}
      <a
        href={links.developerDiscordChannel}
        target="_blank"
        rel="noopener noreferrer"
      >
        Developer Discord channel
      </a>
    </div>
  );
};

export default Info;
