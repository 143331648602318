const commonSettings = {
  cache: "no-cache" as const,
  referrerPolicy: "no-referrer" as const,
  credentials: "same-origin" as const,
};

const catchError = (error: Error, url: string) => {
  console.error(
    `Fetch error. Url — ${url}: `,
    (
      error as {
        message?: any;
      }
    )?.message
  );

  throw error;
};

const checkResponse = async (response: Response) => {
  if (!response.ok) {
    let ok: boolean | undefined;
    let obj: any;
    try {
      const text = await response.text();
      obj = JSON.parse(text);
      ok = !!obj?.info;
    } catch (error) {
      ok = false;
    }
    if (!ok) throw new Error("Bad response");

    return obj;
  }
};

const get = async <T>(
  url = "",
  settings = {} as Record<string, any>
): Promise<T | true | undefined> => {
  try {
    const defoultSettings = {
      ...commonSettings,
      method: "GET",
    };
    const response = await fetch(url, {
      ...defoultSettings,
      ...settings,
    });

    await checkResponse(response);

    try {
      return await response.json();
    } catch (error) {
      return true;
    }
  } catch (error) {
    catchError(error as Error, url);
  }
};

const post = async <T>(
  url = "",
  data = {} as Record<string, any>,
  settings = {} as Record<string, any>
): Promise<T | true | undefined> => {
  try {
    const defoultSettings = {
      ...commonSettings,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(url, {
      ...defoultSettings,
      ...settings,
      headers: {
        ...defoultSettings.headers,
        ...settings.headers,
      },
    });

    const infoObj = await checkResponse(response);

    if (infoObj) {
      return infoObj as T;
    }

    try {
      return await response.json();
    } catch (error) {
      return true;
    }
  } catch (error) {
    catchError(error as Error, url);
  }
};

export { get, post };
